<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>办公系统</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @change="fetchs()"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="单据状态" prop="status" label-width="110px">
          <el-col>
            <el-select v-model="status" clearable @change="fetchs()" style="min-width: 150px;">
              <el-option label="草稿" key="0" value="0" />
              <el-option label="审核中" key="10" value="10" />
              <el-option label="已结束" key="50" value="50" />
              <el-option label="已退回" key="-10" value="-10" />
              <el-option label="已拒绝" key="-50" value="-50" />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="openApply()">创建申请单</el-button>
      <el-button type="danger" icon="delete" @click="dele()">删除</el-button>

      <el-button plain type="info" icon="download" @click="fetchs('Export')"
        style="float:right;margin:0 3px;">导出明细</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:formNameFmt="scope">
        {{ scope.row.createUserName }}申请的{{ scope.row.formName }}
      </template>
      <template v-slot:status="scope">
        <span v-if="scope.row.status == 0">草稿</span>
        <span style="color:#79bbff" v-else-if="scope.row.status == 10">流转中</span>
        <span style="color:#67c23a" v-else-if="scope.row.status == 50">结束</span>
        <span style="color:#e6a23c" v-else-if="scope.row.status == -10">退回</span>
        <span style="color:#f56c6c" v-else-if="scope.row.status == -50">拒绝</span>
        <span v-else>未知</span>
      </template>
      <template v-slot:toolbar="scope">
        <el-button @click="openPage(scope.row)" link size="small">
          <span v-if="scope.row.status == 0">[编辑]</span>
          <span v-else>[查看]</span>
        </el-button>
      </template>
    </static-table>
  </el-card>
</template>
  
<script>
import { fetchInstance, deleteInstance } from "@/api/workflow";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "instance",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "flowName", label: "流程名称", sortable: true, width: "110", showOverflowTooltip: true },
          { prop: "billCode", label: "单据编号", width: "145", sortable: true, showOverflowTooltip: true },
          { prop: "formName", label: "单据类型", sortable: true, width: "100", showOverflowTooltip: true },
          { type: "template", prop: "formNameFmt", label: "单据名称", width: "160", showOverflowTooltip: true },
          { type: 'hidden', prop: "formData", label: "表单数据", searchable: true, showOverflowTooltip: true },
          { type: 'hidden', prop: "formUrl", label: "表单Url", showOverflowTooltip: true },
          { prop: "formDesc", label: "申请描述", searchable: true, width: "270", showOverflowTooltip: true },
          { prop: "remark", label: "备注", searchable: true, width: "80", showOverflowTooltip: true },

          { type: "template", prop: "status", label: "状态", width: "70" },
          { prop: "nodeName", label: "当前节点", width: "100", searchable: true, showOverflowTooltip: true },
          { prop: "operatorName", label: "当前待办人", width: "100", showOverflowTooltip: true, searchable: true },
          { prop: "fileNumber", label: "附件", width: "50", searchable: true },

          { prop: "createDate", label: "创建时间", width: "150", sortable: true, },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "100", },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "updateDate", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
      status: ''
    };
  },
  mounted() {
    this.fetchs();
  },
  methods: {
    fetchs(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      search.filters = [];
      if (!!this.status || this.status == '0')
        search.filters.push({
          name: "status",
          value: this.status,
          operate: 0
        });
      search.method = methodName || '';
      console.log(search);
      fetchInstance(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.pager = { ...res.data };
        }
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    openPage(row) {
      this.$router.push({ path: row.formUrl, query: { id: row.id } });
    },
    openApply() {
      this.$router.push({ path: '/workflow/application', query: {} });
    },
    dele() {
      let that = this;
      if (that.currRow.status != '0' && that.currRow.status != '-10') {
        that.$message({ type: "error", message: "无法删除草稿和退回状态以外的其他单据", });
        return;
      }

      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定", cancelButtonText: "取消", type: "warning",
      })
        .then(() => {
          deleteInstance(that.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({ type: "success", message: "删除成功", });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },

  },
};
</script>